var $j = jQuery.noConflict();

$j(document).ready(function(){
	$j('.endorse-btn').on('click', function(e){
		var $this = $j(this);
		e.preventDefault();
		
		display_id = $this.data('display-id');
		display_name = $this.data('display-name');
		logged_id = $this.data('logged-id');
		logged_name = $this.data('logged-name');
		skill = $this.data('skill');
		count = $this.data('count');
		url = $this.data('url');

		skillParent = $this.data('skill-parent');
		targetCount = $this.data('target-count');

		var data = {
			action: 'c2c_endorse',
			display_id: display_id,
			display_name: display_name,
			logged_id: logged_id,
			logged_name: logged_name, 
			skill: skill,
			count: count
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: url + '/wp-admin/admin-ajax.php',
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				//console.log(response);
			},
			success: function(response, textStatus, jqXHR) {

				$j('#'+targetCount).text('1');
				$j('#'+skillParent).html('<div class="endorse endorsed generic_button">Endorsed</div>');

				var endorsementsCountBanner = $j('#endorsements-count-banner');
				if(count === 0){
					count = 1;
					endorsementsCountBanner.text(count + ' Endorsement');
				}else{
					endorsementsCountBanner.text(count + ' Endorsements');
				}
				$j('.endorse-btn').data('count', count + 1);
			}
		});
	});

	$j('#shell-company-invite').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#shell-company-actions-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		if( $this.hasClass('is-sent') ){
			$message.text('Sorry, only one invitation may be sent to this company.').addClass('alert alert-danger');
			return;
		}
		
		companyId = $this.data('company-id');
		userId = $this.data('user-id');

		var data = {
			action: 'shell_invite_company',
			companyId: companyId,
			userId: userId
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				// console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				// console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.addClass('is-sent');
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});

	$j('#shell-company-add-employee').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#shell-company-actions-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		if( $this.hasClass('is-employed') ){
			$message.text('You work here already!').addClass('alert alert-danger');
			return;
		}
		
		companyId = $this.data('company-id');
		userId = $this.data('user-id');

		var data = {
			action: 'shell_company_employment',
			companyId: companyId,
			userId: userId
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				// console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				// console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.addClass('is-employed');
					$j('.average-2score .two-score').text(response.newscore); 
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});

	$j('#shell-company-candidate-claim').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#shell-company-actions-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		if( $this.hasClass('is-claimed') ){
			$message.text('You have already claimed this profile!').addClass('alert alert-danger');
			return;
		}
		
		companyId = $this.data('company-id');
		userId = $this.data('user-id');

		var data = {
			action: 'shell_company_candidate_claim',
			companyId: companyId,
			userId: userId
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				// console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				// console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.addClass('is-claimed');
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});

	$j('#report-btn').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#report-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		profileId = $this.data('profile-id');
		userId = $this.data('user-id');

		var data = {
			action: 'profile_report',
			profileId: profileId,
			userId: userId
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				// console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				// console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.fadeOut();
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});

	$j('#contact-btn').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#contact-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		profileId = $this.data('profile-id');
		userId = $this.data('user-id');
		type = $this.data('type');

		var data = {
			action: 'profile_contact',
			profileId: profileId,
			userId: userId,
			type: type
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				// console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				// console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.fadeOut();
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});

	$j('.job-inquiry-btn').on('click', function(e){
		e.preventDefault();

		var $this = $j(this),
			$message = $j('#inquiry-message');

		$message.text('').removeClass('alert alert-success alert-danger');
		
		var data = {
			action: 'job_inquiry',
			to: $this.data('to'),
			subject: $this.data('subject'),
			userProfile: $this.data('user-profile'),
			userName: $this.data('user-name'),
			userEmail: $this.data('user-email')
		};

		$j.ajax({
			cache: false,
			dataType: 'json',
			url: ajaxurl,
			type: 'POST',
			data: data,
			error: function(response, textStatus, errorThrown) {
				console.log(response);
			},
			success: function(response, textStatus, jqXHR) {
				console.log(response);
				if( response.status === 'success' ){
					$message.text(response.message).addClass('alert alert-success');
					$this.fadeOut();
				}else if( response.status === 'error' ){
					$message.text(response.message).addClass('alert alert-danger');
				}
			}
		});
	});
});