var ljContactForm = {
	init: function(){
		ljContactForm.ajaxSubmit();
	},
	ajaxSubmit: function(){
		jQuery('#invite-colleagues').on('click', function(event){
			event.preventDefault();

			var text = jQuery('#invite-colleagues-text');
			var emailField = jQuery('#email');
			var messageField = jQuery('#message');

			text.empty();
			emailField.val();
			messageField.val();

			var name = jQuery(this).data('name');
			var email = jQuery('#email').val();
			var message = jQuery('#message').val();
			var url = jQuery(this).data('url');

			if(email && message){
				var data = {
					action: 'ajaxcontact_send_mail',
					name: name,
					email: email,
					message: message
				};

				jQuery.ajax({
					url: url + '/wp-admin/admin-ajax.php',
					type: 'POST',
					cache: false,
					dataType : 'json',
					data: data,
					error: function(data, textStatus, errorThrown){
						console.log(data);
					},
					success:function(response, textStatus, jqXHR){
						console.log(response);
						text.html("<span class='alert'>You've succssfully sent an invite email to <strong>"+email+"</strong>. Submit another email address to invite another colleague.</span>");
					}
				});
			}else{
				text.html("<span class='error'>Please enter an email and message.</span>");
			}
		});
	}
};

var termsAndService ={
	init: function(){
		termsAndService.util();
	},
	el:{
		checkbox: jQuery('#tos_agree'),
		button: jQuery('.registration  .product_type_subscription'),
		alert: jQuery('.checkbox-alert')
	},
	util: function(){
		termsAndService.el.button.click(function(e){
			if(!termsAndService.el.checkbox.prop('checked')){
				e.preventDefault();
				termsAndService.el.alert.html('<div class="alert">Please Agree To the Terms and Conditions and Privacy Policy.</div>');
				jQuery('html, body').animate({ scrollTop: 0 }, 1000);
			}
		});
	}
};
jQuery(document).ready(function($){

	$("#content-wrapper").fitVids();

	// There's not a filter for these, so updating on the client side. 
	var $friendsNavItem = $('#user-friends');
	var $friendsNotificationSettingsTable = $('#friends-notification-settings');
	if( $friendsNavItem.length > 0 ){
		var friendsCount = $friendsNavItem.find('.count').text();
		$friendsNavItem.html('Connections <span class="count">'+friendsCount+'</span>');
	}
	if( $friendsNotificationSettingsTable.length > 0 ){
		$friendsNotificationSettingsTable.find('th.title').text('Connections');
		$friendsNotificationSettingsTable.find('td').each(function(){
			var text = $(this).text();
			if(text.indexOf("friendship request") >= 0){
				$(this).text( text.replace('friendship request', 'connection request') );
			}
		});
	}

	$('.menu-toggle').on('click', function(e){
	    e.preventDefault();
	    $('body').toggleClass('is-menu');
	});
});
jQuery(window).load(function(){
	termsAndService.init();
	ljContactForm.init();
	jQuery('.connection-tooltip, .endorsement-with-tooltip, .un-endorse, .avatar-link, .main-badge, .2score-tooltip').tooltip();
	jQuery('.collapse').collapse({
		toggle: true
	});

	jQuery('#ab-pending-notifications').parent('a').click(function(e){
		e.preventDefault();
	});

	function c2cCorrectUserName(){
		var fullName = jQuery('#field_1, #field_2');
		var fullNameValue = fullName.attr('value');
		var fullNameText = fullName.attr('value');
		if(fullNameValue){
			//fullNameValue = fullNameValue.replace(/[^a-zA-Z ]/g, '').replace(/\s+/g, '-').toLowerCase();

			capitalizedName = fullNameText.replace(/\w\S*/g, function(fullNameText){return fullNameText.charAt(0).toUpperCase() + fullNameText.substr(1).toLowerCase();});
			fullName.val(capitalizedName);

			// var text = '';
			// var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

			// for( var i=0; i < 5; i++ ){
			// 	text += possible.charAt(Math.floor(Math.random() * possible.length));
			// }

			// jQuery('#signup_username').val(fullNameValue + '-' + text);
			// console.log(jQuery('#signup_username').val());
		}
		
	}
	
	function makeCityPretty(){
		city = jQuery('#field_62, #field_3');
		cityVal = city.val();
		if(city){
			capitalizedCity = cityVal.replace(/\w\S*/g, function(cityVal){return cityVal.charAt(0).toUpperCase() + cityVal.substr(1).toLowerCase();});
			city.val(capitalizedCity);
		}
	}

	// jQuery('#field_1, #field_2').focusout(function(){
	// 	c2cCorrectUserName();
	// });

	jQuery('#field_62, #field_3').focusout( function(){
		makeCityPretty();
	});
	// if(jQuery('#field_1').val()){
	// 	c2cCorrectUserName();
	// }
	if(jQuery('#field_62').val()){
		makeCityPretty();
	}

	jQuery('#two-tab-carousel').carousel({
		interval: 3000
	});

	if( jQuery(window).width() < 992 ){
		var adminBarItems = jQuery('#wp-admin-bar-my-account-default li');
		jQuery('#nav .menu').prepend(adminBarItems);
		jQuery('#wp-admin-bar-logout').hide();
		jQuery('#my-profile-link').hide();
	}
			
});

